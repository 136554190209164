export const FORGET_PASSWORD_VERIFY_OTP = `FORGET_PASSWORD_VERIFY_OTP`;
export const FORGET_PASSWORD_VERIFY_OTP_SUCCESS = `FORGET_PASSWORD_VERIFY_OTP_SUCCESS`;
export const FORGET_PASSWORD_VERIFY_OTP_FAILURE = `FORGET_PASSWORD_VERIFY_OTP_FAILURE`;


export const forgetPasswordVerifyOtp = (data, callback) => {
    return {
        type: FORGET_PASSWORD_VERIFY_OTP,
        data,
        callback
    }
}

export const forgetPasswordVerifyOtpSuccess = (data) => {
    return {
        type: FORGET_PASSWORD_VERIFY_OTP_SUCCESS,
        data
    }
}

export const forgetPasswordVerifyOtpFailure = (error) => {
    return {
        type: FORGET_PASSWORD_VERIFY_OTP_FAILURE,
        error
    }
}