export const UPDATE_PASSWORD = `UPDATE_PASSWORD`;
export const UPDATE_PASSWORD_SUCCESS = `UPDATE_PASSWORD_SUCCESS`;
export const UPDATE_PASSWORD_FAILURE = `UPDATE_PASSWORD_FAILURE`;


export const updatePassword = (data, callback) => {
    return {
        type: UPDATE_PASSWORD,
        data,
        callback
    }
}

export const updatePasswordSuccess = (data) => {
    return {
        type: UPDATE_PASSWORD_SUCCESS,
        data
    }
}

export const updatePasswordFailure = (error) => {
    return {
        type: UPDATE_PASSWORD_FAILURE,
        error
    }
}