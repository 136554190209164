import { doAction, doResponseAction } from ".";

const NAME = "CLIENT_CREDENTIALS";
const CLIENT_CREDENTIALS = `${NAME}/CLIENT_CREDENTIALS`;
const CLIENT_CREDENTIALS_SUCCESS = `${NAME}/CLIENT_CREDENTIALS_SUCCESS`;
const CLIENT_CREDENTIALS_FAILURE = `${NAME}/CLIENT_CREDENTIALS_FAILURE`;

export {
    CLIENT_CREDENTIALS,
    CLIENT_CREDENTIALS_SUCCESS,
    CLIENT_CREDENTIALS_FAILURE
}

export const clientCredentials = (params, callback) => {

    return doAction(CLIENT_CREDENTIALS, params, callback);
}

export const clientCredentialsSuccess = (data) => doResponseAction(CLIENT_CREDENTIALS_SUCCESS, data)

export const clientCredentialsFailure = (error) => doResponseAction(CLIENT_CREDENTIALS_FAILURE, error)