import moment from 'moment'
// TODO: CHANGE_DOMAIN_URL
export const URLS = {
    commonLogin: 'https://shop-login.proglint.com/',
    storeManager: 'https://shop-admin.proglint.com/dashboard',
    bistroDisplay: 'https://shop-admin.proglint.com/bistro/display/orders',
    BackKitchen: 'https://shop-admin.proglint.com/chef/orders',
    cookieDomain: '.proglint.com',
    PIM: 'https://shop-pim.proglint.com/'
}
export const RoleIds = {
    Admin: 0,
    storeManager: 1,
    bistroAssociate: 2,
    storeAssociate: 3,
    backKitchen: 11,
    bistroDisplay: 12,
    frontKitchen: 13
}

const Constants = {
    FORGET_PASSWORD: {
        title: "Forgot Password",
        sendButton: "Send",
        error: {
            emailId: 'Please enter valid Email Id.',
            emailIdEmpty: 'Please enter Email Id.'
        }
    },
    FOOTER_TEXT: {
        text: `Copyright © ${moment().format('YYYY')}, Proglint`  //TODO: Copyright changes
    },
}

export default Constants;

