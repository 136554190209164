import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import LoginScreen from './app/containers/LoginScreen';

export const OurFallbackComponent = ({ error, resetErrorBoundary }) => {
    return (
        <div>
            <h1>An error occurred: {error.message}</h1>
            <button onClick={resetErrorBoundary}>Sorry something went wrong. Please refresh the page</button>
        </div>
    );
};



const RoutePagesConfig = () => {
    return (
        <ErrorBoundary FallbackComponent={OurFallbackComponent}>
            <React.Fragment>
                <Switch>
                    <Route path={'/'} component={LoginScreen} exact={true} />
                </Switch>
            </React.Fragment>
        </ErrorBoundary>
    )
}

export default RoutePagesConfig;
