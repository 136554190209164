import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import LoginReducer from "./LoginReducer";
import ClientCredentialsReducer from './ClientCredentials';
import ForgotPassword from './forgotPassword'

const rootReducer = routeHistory => combineReducers({
    router: connectRouter(routeHistory),
    LOGIN_CREDS: LoginReducer,
    CLIENT_CREDS: ClientCredentialsReducer,
    FORGOT_PASSWORD: ForgotPassword,
});

export default rootReducer;