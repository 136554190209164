import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { history } from '../redux/store';

import AppButton from "../components/fields/AppButton";
import BoldError from "../components/fields/BoldError";
import PasswordCheckPopover from "../components/fields/PasswordCheckPopover";

import Actions from "../redux/actions";

import { RoleIds, URLS } from "../utils/Constants";
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getQueryParams } from "../utils/commonFunctions/EncodeDecodeBase64";
import {
    validatedigitsRegExp, validatelowercaseRegExp, validateminLengthRegExp,
    validatespecialCharRegExp, validateuppercaseRegExp
} from "../utils/validation";
import AppLoader from "../components/fields/AppLoader";



class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: "",
            reTypePassword: "",
            passwordValid: false,
            errorMessage: null,
            showPassword: false,
            showPopover: false,
            isResetLoading: false
        };



    }


    componentDidMount() {
        const token = this.isJSON(this.getCookie('token')) ? JSON.parse(this.getCookie('token')) : undefined
        const { location } = history;

        const queryParams = getQueryParams(location.search)
        const origin = queryParams.get('origin')
        const path = queryParams.get('path') || '/dashboard'

        if (origin && token && Object.keys(token).length !== 0) {
            const url = new URL(origin)
            url.searchParams.set('path', path)
            window.location.assign(url)
        }

        if (token && Object.keys(token).length !== 0) {
            const url = new URL(URLS.storeManager)
            window.location.assign(url)
        }
    }


    isJSON(str) {
        try {
            return (JSON.parse(str) && !!str);
        } catch (e) {
            return false;
        }
    }
    getCookie(name) {
        let cookie = {};
        document.cookie.split(';').forEach(function (el) {
            let [k, v] = el.split('=');
            cookie[k.trim()] = v;
        })
        return cookie[name];
    }

    resetPassword() {
        const { reTypePassword, newPassword } = this.state;
        if (!newPassword) {
            this.setState({ errorMessage: "Please enter the password" })
            return;
        } else if (!reTypePassword) {
            this.setState({ errorMessage: 'Please Retype the password' });
            return;
        } else if (!validateuppercaseRegExp(newPassword)) {
            this.setState({ errorMessage: 'Please enter valid password' });
            return
        } else if (!validatelowercaseRegExp(newPassword)) {
            this.setState({ errorMessage: 'Please enter valid password' });
            return
        } else if (!validatespecialCharRegExp(newPassword)) {
            this.setState({ errorMessage: 'Please enter valid password' });
            return
        } else if (!validatedigitsRegExp(newPassword)) {
            this.setState({ errorMessage: 'Please enter valid password' });
            return
        } else if (!validateminLengthRegExp(newPassword)) {
            this.setState({ errorMessage: 'Please enter valid password' });
            return
        } else if (reTypePassword !== newPassword) {
            this.setState({ errorMessage: 'Password and Re-type passowrd does not match.' });
            return
        }

        const callback = (res) => {
            this.setState({ isResetLoading: false })
            const { response_message } = res || {}

            if (res?.access_token) {
                const { customer, access_token, refresh_token } = res || {}
                this.setState({ errorMessage: '' })
                const { location } = history;
                const queryParams = new URLSearchParams(location?.search)
                const origin = queryParams.get('origin')
                const path = queryParams.get('path') || '/dashboard'

                const expires = (new Date(Date.now() + parseInt(res?.expires_in) * 1000)).toUTCString();
                const data = JSON.stringify(res)
                const getLastRole = this.getCookie('roleId') ? this.getCookie('roleId') : undefined
                let expiration_date = new Date();
                expiration_date.setMonth(expiration_date.getMonth() + 1);
                document.cookie = `token=${data};domain=${URLS.cookieDomain};path=/;Expires=${expiration_date}`
                document.cookie = `access_token=${access_token};domain=${URLS.cookieDomain};path=/; Expires=${expires};Expires=${expiration_date}`
                document.cookie = `refresh_token=${refresh_token};domain=${URLS.cookieDomain};path=/;Expires=${expiration_date}`

                if (customer?.roleId !== RoleIds.backKitchen && customer?.roleId !== RoleIds.bistroDisplay
                    && customer?.roleId !== RoleIds.frontKitchen) {
                    if (origin && (customer?.roleId === RoleIds.Admin || customer?.roleId === RoleIds.storeAssociate
                        || customer?.roleId === RoleIds.bistroAssociate || customer?.roleId === RoleIds.storeManager)) {
                        if (parseInt(getLastRole) === customer?.roleId) {
                            const url = new URL(origin + path)
                            window.location.assign(url)
                        } else {
                            const url = new URL(URLS.storeManager)
                            window.location.assign(url)
                        }

                    }
                    else {
                        const url = new URL(URLS.storeManager)
                        window.location.assign(url)
                    }
                } else {
                    if (customer?.roleId === RoleIds.backKitchen) { window.location.assign(URLS.BackKitchen) }
                    else if (customer?.roleId === RoleIds.bistroDisplay) { window.location.assign(URLS.bistroDisplay) }
                    else { window.location.assign(URLS.BackKitchen) }
                }
                this.props.updateCurrentScreen('Login')
            } else {
                this.setState({ errorMessage: response_message })
            }

        }
        let params = {
            password: reTypePassword,
            type: 2
        }
        this.props.updateNewPassword(params, callback)
        this.setState({ isResetLoading: true })
    }

    openLoginPage() {
        this.props.updateCurrentScreen('Login')
    }

    handleEyeIcon() {
        this.setState({ showPassword: !this.state.showPassword })
    }
    render() {
        const { errorMessage, isResetLoading } = this.state;
        return (
            <> <div className="row">
                <div className="col-12 col-lg-5 m-auto">
                    <div className="card card-body p-50">
                        <div className="row align-items-center mb-4">
                            <div className="col">
                                <h2 className="font28 fw-bold">Reset Password </h2>
                            </div>
                        </div>
                        <BoldError message={errorMessage} />
                        <div className="row mb-4">
                            <div className="col"> Please enter your new password with which you would like to access</div>
                        </div>
                        <div className="row align-items-center mb-4">
                            <div className="input-group mb-2">
                                {this.state.showPopover && <PasswordCheckPopover value={this.state.newPassword} />}
                                <input
                                    id='Password'
                                    className="form-control"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    name="password"
                                    onFocus={() => this.setState({ showPopover: true })}
                                    onBlur={() => this.setState({ showPopover: false })}
                                    onChange={(e) => this.setState({ newPassword: e.target.value })}
                                    placeholder="New Password"
                                    aria-describedby="view-pass" />
                                <button className="btn border-dark"
                                    onClick={() => this.handleEyeIcon()}
                                    type="button"
                                    id="view-pass">
                                    <FontAwesomeIcon
                                        style={{ cursor: 'pointer' }}
                                        icon={this.state.showPassword ? faEye : faEyeSlash} />
                                </button>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="input-group mb-4">
                                <input
                                    id='setPassword'
                                    className="form-control"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    name="password"
                                    onChange={(e) => this.setState({ reTypePassword: e.target.value })}
                                    placeholder="Retype Password"
                                    aria-describedby="view-pass" />
                                <button className="btn border-dark"
                                    onClick={() => this.handleEyeIcon()}
                                    type="button"
                                    id="view">
                                    <FontAwesomeIcon
                                        style={{ cursor: 'pointer' }}
                                        icon={this.state.showPassword ? faEye : faEyeSlash} />
                                </button>
                            </div>
                            {this.state.reTypePassword && this.state.newPassword !== this.state.reTypePassword &&
                                <p style={{ color: 'red', fontWeight: 24 }}>Password does not match</p>}
                        </div>
                        <div className="text-center pt-4 mb-2">
                            <AppButton id='AppButton' title="RESET PASSWORD" onClick={() => this.resetPassword()} />
                        </div>
                        <div className="text-start">
                            <span className="text-dark cursor-pointer" id="openLoginPage" onClick={() => this.openLoginPage()}><strong>Back to login</strong></span>
                        </div>
                    </div>
                </div>
            </div>
                {isResetLoading && <AppLoader />}
            </>
        );
    }
}


export const mapStateToProps = (state) => {
    return {
    };
};

export default withRouter(connect(mapStateToProps, {
    updateNewPassword: Actions.updatePassword,
    updateCurrentScreen: Actions.updateCurrentScreen,
})(ResetPassword))

