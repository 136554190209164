import * as loginActions from './LoginAction';
import * as clientActions from './ClientCredentialsAction';
import * as requestOtp from './RequestOtp'
import * as updatePassword from './UpdatePassword'
import * as verifyOtp from './VerifyOtp'

const Actions = {
    ...loginActions,
    ...clientActions,
    ...requestOtp,
    ...updatePassword,
    ...verifyOtp,
}

export default Actions;

export const doAction = (type, params = {}, callback) => ({ type, params, callback });
export const doResponseAction = (type, data = {}) => ({ type, data });
