
import { all } from 'redux-saga/effects'
import credentialsListener from './ClientCredentialsSaga';
import loginSagaListener from './LoginSaga';
import forgotPassword from './ForgotPassword';
export default function* sagaWatchers() {
        yield all([
                loginSagaListener(),
                credentialsListener(),
                forgotPassword()
        ]);
}