import { createStore, applyMiddleware, compose } from 'redux';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers';
import sagaWatchers from './saga';


export const history = createBrowserHistory();

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['router'],
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_PERSISTOR_SECRET,
      onError: function (error) {
        // Handle the error.
      },
    }),
  ],

};
const sagaMiddleware = createSagaMiddleware();
const middleware = applyMiddleware(sagaMiddleware, routerMiddleware(history));
const persistedReducer = persistReducer(persistConfig, rootReducer(history));

let composedMiddleware = compose(middleware);
if (!process.env.REACT_APP_DEBUG) {
  composedMiddleware = composeWithDevTools(middleware);
}
export const store = createStore(persistedReducer, {}, composedMiddleware);
export const persistor = persistStore(store);

sagaMiddleware.run(sagaWatchers);