import axios from 'axios';
import { getClientCredentialsToken } from '../../redux/reducers/ClientCredentials';
import { store } from '../../redux/store';
import END_POINTS, { BASE_URL } from './Endpoints';
import Actions from '../../redux/actions/index';
import MomentFunctions from '../commonFunctions/MomentFunctions';


const axiosInstance = axios.create({
    baseURL: BASE_URL,
});
export const publicHeaders = () => {
    const selectedStoreId = store?.getState()?.STORE_LIST?.selectedStoreData
    return {
        'Authorization': "Basic dXN0and0Y2xpZW50aWQ6dXN0UmQ5M0NmNFdfRm13WiY=",
        "Content-Type": "multipart/form-data",
        'storeId': selectedStoreId?.value || 1,

    }
}

export const httpAuthHeader = () => {
    const token = store?.getState()?.LOGIN_CREDS?.token?.access_token;
    const selectedStoreId = store?.getState()?.STORE_LIST?.selectedStoreData

    return {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/json',
        'storeId': selectedStoreId?.value || 1,

    }
}

//generate client credentials
export const clientCredentials = (url, data) => {
    const config = {
        headers: publicHeaders()
    };
    return axiosInstance.post(url, data, config)
        .then(function (response) {
            return response.data
        }).catch((error) => {
            throw error
        })
}

export const refreshToken = refresh_token => {
    let data = new FormData()
    data.append('grant_type', 'refresh_token')
    data.append('refresh_token', refresh_token)
    const config = {
        headers: publicHeaders()
    };
    return axiosInstance.post(END_POINTS.CLIENT_CREDENTIALS, data, config)
        .then(function (response) {
            return response.data
        }).catch((error) => {
            throw error
        })
};

// added interceptors for refresh token strategy
axiosInstance.interceptors.request.use(
    async config => {
        return config;
    },
    error => {
        Promise.reject(error)
    });

axiosInstance.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const token = store?.getState()?.LOGIN_CREDS?.token?.refresh_token;
        if (token) {
            const refreshTokenObj = await refreshToken(token);
            if (refreshTokenObj && refreshTokenObj.access_token) {
                refreshTokenObj.expMilliseconds = MomentFunctions.convertExpirySecToMilliSec(refreshTokenObj.expires_in);
            }
            store.dispatch(Actions.loginSuccessAction(refreshTokenObj))
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + refreshTokenObj.access_token;
            originalRequest.headers.Authorization = `bearer ${refreshTokenObj.access_token}`
            return axiosInstance(originalRequest);
            // if refreshtoken throws error
        }
    } else if (error.response.status >= 401 && error.response.status < 500) {
        store.dispatch(Actions.logoutSuccessAction())
    } else{
        
    }
    return Promise.reject(error);
});


export const clientCredentialsHeaders = () => {
    const token = getClientCredentialsToken(store?.getState())?.access_token;
    const selectedStoreId = store?.getState()?.STORE_LIST?.selectedStoreData

    return {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/json',
        'storeId': selectedStoreId?.value || 1,
    }
}


export const checkResponse = (response) => {
    if (typeof response?.data === 'string') {
        return response.data;
    }
    if (response.status === 200 && response.data.response_code === 0) {
        return response.data;
    } else if (response.status === 200 && response.data.response_code !== 0) {
        throw response.data.response_message;
    }
    return response;
}




export const doAuthPost = (url, data) => {
    const config = {
        headers: httpAuthHeader()
    };
    return axiosInstance.post(url, data, config)
        .then(function (response) {
            return checkResponse(response)
        }).catch((error) => {
            throw error
        })
}


// Client Request



export const doClientPost = (url, data) => {
    const config = {
        headers: clientCredentialsHeaders(),
    };
    return axiosInstance.post(url, data, config)
        .then(function (response) {
            return response.data
        }).catch((error) => {
            throw error
        })
}










