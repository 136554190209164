import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./asset/css/custom.css"
import './asset/css/styles.css'
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <React.Fragment>
    <App />
  </React.Fragment>,
  document.getElementById('root')
);

reportWebVitals(console.log);
