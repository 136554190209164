export const EncodeBase64 = (data) => {
    let buff = new Buffer(data);
    return buff.toString('base64');
}


export const DecodeBase64 = (data) => {
    let buff = new Buffer(data, 'base64');
    return buff.toString('ascii');
}

export const getQueryParams = (searchPath) => {
    return new URLSearchParams(searchPath);
}