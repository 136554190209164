import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import Header from '../components/Header';
import Footer from '../components/Footer';
import ForgotPassword from './Forgotpassword'
import ResetPassword from "./ResetPassword";
import AppLoader from '../components/fields/AppLoader';

import AppButton from '../components/fields/AppButton';
import BoldError from '../components/fields/BoldError';
import InputField from '../components/fields/InputField';

import Actions from '../redux/actions';
import { currentScreenPath, getLoginLoadingSelector } from '../redux/reducers/LoginReducer';

import { isValidEmail } from '../utils/commonFunctions/Validations';
import { RoleIds, URLS } from '../utils/Constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';




function getCookie(name) {
    let cookie = {};
    document.cookie.split(';').forEach(function (el) {
        let [k, v] = el.split('=');
        cookie[k.trim()] = v;
    })
    return cookie[name];
}

const LoginScreen = () => {
    const [isShowLoginScreen, setLoginScreen] = useState(false)
    const [emailId, setEmailId] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const location = useLocation();
    const [forgetModal, setForgotModal] = useState(false)
    const currentScreen = useSelector(currentScreenPath);
    const isLoading = useSelector(getLoginLoadingSelector);
    const [showPassword, setShowPassword] = useState(false)
    const dispatch = useDispatch();

    const queryParams = new URLSearchParams(location?.search)
    const origin = queryParams.get('origin')
    const path = queryParams.get('path') || '/dashboard'


    function isJSON(str) {
        try {
            return (JSON.parse(str) && !!str);
        } catch (e) {
            return false;
        }
    }


    useEffect(() => {
        const token = isJSON(getCookie('token')) ? JSON.parse(getCookie('token')) : undefined
        if (origin && token && Object.keys(token).length !== 0) {
            const url = new URL(origin)
            url.searchParams.set('path', path)
            window.location.assign(url)
        } else if (token && Object.keys(token).length !== 0) {
            const url = new URL(URLS.storeManager)
            window.location.assign(url)
        } else {
            dispatch(Actions.updateCurrentScreen('Login'))
            setLoginScreen(true)
        }

    }, [origin, path, dispatch])


    const onLoginClick = () => {
        let error = ''
        if (emailId.trim() === '') {
            error = 'Please enter Email Id.'
        } else if (!isValidEmail(emailId)) {
            error = 'Email Id not valid';
        } else if (password.trim() === '') {
            error = 'Please enter password.';
        } else if (password.length < 5) {
            error = 'Password must be more than 5 characters';
        }

        if (error !== '') {
            setError(error);
            return;
        }
        const callback = (res) => {
            const { customer } = res || {}
            if (res?.access_token) {
                setError(null)
                const data = JSON.stringify(res)
                const getLastRole = getCookie('roleId') ? getCookie('roleId') : undefined
                let expiration_date = new Date();
                expiration_date.setMonth(expiration_date.getMonth() + 1);
                document.cookie = `token=${data};domain=${URLS.cookieDomain};path=/;Expires=${expiration_date}`
                document.cookie = `access_token=${res.access_token};domain=${URLS.cookieDomain};path=/; Expires=${expiration_date};`
                document.cookie = `refresh_token=${res.refresh_token};domain=${URLS.cookieDomain};path=/;Expires=${expiration_date}`

                if (customer?.roleId !== RoleIds.backKitchen && customer?.roleId !== RoleIds.bistroDisplay
                    && customer?.roleId !== RoleIds.frontKitchen) {
                    if (origin && (customer?.roleId === RoleIds.Admin || customer?.roleId === RoleIds.storeAssociate
                        || customer?.roleId === RoleIds.bistroAssociate || customer?.roleId === RoleIds.storeManager)) {
                        if (parseInt(getLastRole) === customer?.roleId) {
                            const url = new URL(origin + path)
                            window.location.assign(url)
                        } else if (origin && origin.includes(URLS.PIM)) {
                            const url = new URL(URLS.PIM)
                            window.location.assign(url)
                        } else {
                            const url = new URL(URLS.storeManager)
                            window.location.assign(url)
                        }

                    }
                    else {
                        const url = new URL(URLS.storeManager)
                        window.location.assign(url)
                    }
                } else {
                    if (customer?.roleId === RoleIds.backKitchen) { window.location.assign(URLS.BackKitchen) }
                    else if (customer?.roleId === RoleIds.bistroDisplay) { window.location.assign(URLS.bistroDisplay) }
                    else { window.location.assign(URLS.BackKitchen) }
                }
            } else {
                setError(res?.response_message);
            }

        }
        dispatch(Actions.loginAction({ userName: emailId, password }, callback));
    }

    const openForgetPage = () => { dispatch(Actions.updateCurrentScreen('forget')) }
    const onCloseModal = () => { setForgotModal(false) }
    const handleNextKeyUp = (e) => {
        if (e.key === 'Enter') { e.preventDefault(); onLoginClick() }
    }
    return (
        <>
            {
                isShowLoginScreen && <>
                    <Header />
                    <div className="wrap my-5">
                        {(currentScreen === 'Login' || !currentScreen) && <div className="row">
                            <div className="col-12 col-lg-5 m-auto">
                                <div className="card card-body p-50">
                                    <div className="row align-items-center mb-5">
                                        <div className="col">
                                            <h2 className="font28 fw-bold">Login </h2>
                                        </div>
                                    </div>
                                    <BoldError message={error} />
                                    <div className="row align-items-center mb-4">
                                        <div className="col">
                                            <InputField
                                                defaultValue={emailId}
                                                placeholder="Email Id"
                                                onChange={value => setEmailId(value)} />
                                        </div>
                                    </div>
                                    <div className="input-group mb-4">
                                        <input
                                            id='setPassword'
                                            className="form-control"
                                            type={showPassword ? 'text' : 'password'}
                                            name="password"
                                            onChange={(e) => setPassword(e?.target?.value)}
                                            onKeyUp={(event) => handleNextKeyUp(event)}
                                            placeholder="Password"
                                            aria-describedby="view-pass" />
                                        <button className="btn border-dark"
                                            onClick={() => setShowPassword(!showPassword)}
                                            type="button"
                                            id="view-pass">
                                            <FontAwesomeIcon
                                                style={{ cursor: 'pointer' }}
                                                icon={showPassword ? faEye : faEyeSlash} />
                                        </button>
                                    </div>
                                    <div className="row mb-4">
                                        <div
                                            style={{ cursor: 'pointer' }}
                                            className="col text-end">
                                            <span id='OpenForget' className="text-dark"
                                                onClick={() => openForgetPage()}
                                            >Forgot Password?</span>
                                        </div>
                                    </div>
                                    <div className="text-center pt-4">
                                        <AppButton id='onLoginClick' title="Login" onClick={onLoginClick} />
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {currentScreen === 'forget' && <ForgotPassword
                            isModalOpen={forgetModal}
                            onCloseModal={onCloseModal}
                            successEmail={(forgotEmail) => { }}
                        />}
                        {currentScreen === 'reset' && <ResetPassword />}
                    </div>
                    <Footer />
                    {isLoading && <AppLoader />}
                </>
            }

        </>
    );
};

export default LoginScreen;
