import React from 'react';
import ProglintLogo from '../../asset/img/proglint.png'
const Header = () => {
    return (
        <div id="header-shadow" className="border-bottom shadow-sm">
            <div className="wrap">
                <header id="header">
                    {/* TODO: Header Logo change */}
                    <div className="">
                        <div className="text-center">
                            <span>
                                <img src={ProglintLogo} alt="UST"
                                    className="login_logo" /></span>  
                        </div>
                    </div>
                </header>
            </div>
        </div>
    )
}

export default Header;