import React from 'react'
import Constants from '../utils/Constants'
import ProglintLogo from '../../asset/img/proglint_footer.svg'
const Footer = () => {
    return (
        <footer id="footer" className="bg-cream">
            <div className="wrap">
                {/* TODO: Footer Logo change */}
                <div className="text-center">
                    <img src={ProglintLogo} alt="UST"
                        className="footer-logo mb-4" />
                    <div
                        className="font-weight-normal">
                        {Constants.FOOTER_TEXT.text}
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer