import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import AppButton from "../components/fields/AppButton";
import InputField from "../components/fields/InputField";
import BoldError from "../components/fields/BoldError";

import { isValidEmail, isFieldEmpty } from '../utils/validation'
import Constants from "../utils/Constants";

import Actions from "../redux/actions";

import AppLoader from "../components/fields/AppLoader";
class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                email: '',
                otp: ''
            },
            errors: {
                email: '',
                otp: ''
            },
            errorMessage: '',
            verifyOtp: false,
            isVerifyOtpLoading: false
        };
    }

    requestOtp() {
        const { email } = this.state.fields
        if (this.handleSubmitValidation(["email"])) {
            const callback = (response) => {
                let { response_code, response_message } = response
                if (response_code === 2) {
                    this.setState({ errorMessage: response_message })
                } else {
                    this.props.successEmail(email)
                    this.setState({ errorMessage: '', verifyOtp: true })
                }
                this.setState({ isVerifyOtpLoading: false })
            }
            const params = {
                userName: email,
                type: 2
            }

            this.props.requestOtp(params, callback)
            this.setState({ isVerifyOtpLoading: true })
        }
    }
    handleChange(e) {
        let { fields } = this.state
        let { name, value } = e.target
        fields[name] = value
        this.setState({ fields }, () => {
            this.handleValidation(name)
        })
    }

    handleValidation(field) {
        let { fields, errors } = this.state;
        if (field === 'email') {
            if (isFieldEmpty(fields[field])) {
                errors[field] = Constants.FORGET_PASSWORD.error.emailIdEmpty;
                this.setState({ errors })
                return false
            } else if (!isValidEmail(fields[field])) {
                errors[field] = Constants.FORGET_PASSWORD.error.emailId;
                this.setState({ errors })
                return false
            }
        }
        errors[field] = ''
        this.setState({ errors })
        return true;
    }
    handleSubmitValidation(fields) {
        let isFormValid = true;
        fields.forEach((field) => {
            if (!this.handleValidation(field)) {
                isFormValid = false
            }
        })
        return isFormValid;
    }

    verifyotp() {
        const { otp } = this.state.fields;
        if (otp === null || otp.length <= 0) {
            this.setState({ errors: { otp: 'Please enter the otp' } });
            return;
        }
        // login flow
        const callback = (response) => {
            let { response_code, response_message } = response
            if (response_code === 0) {
                this.setState({ errorMessage: null })
                this.props.updateCurrentScreen('reset')
            } else {
                this.setState({ errorMessage: response_message })
            }
            this.setState({ isVerifyOtpLoading: false })
        }
        const { forgetEmail } = this.props.getForgetEmail
        let params = {
            emailId: forgetEmail,
            emailOtp: otp,
            type: 2
        }
        this.props.forgetPasswordVerifyOtp(params, callback)
        this.setState({ isVerifyOtpLoading: true })
    }

    openLoginPage() {
        this.props.updateCurrentScreen('Login')
    }
    render() {
        const { fields, errors, errorMessage, verifyOtp, isVerifyOtpLoading } = this.state;
        return (
            <>
                <div className="wrap my-5">
                    <div className="row">
                        <div className="col-12 col-lg-5 m-auto">
                            <div className="card card-body p-50">
                                <div className="row align-items-center mb-4">
                                    <div className="col">
                                        <h2 className="font28 fw-bold">Forgot Password </h2>
                                    </div>
                                </div>
                                <BoldError message={errorMessage} />
                                <form>
                                    <div className="row mb-4">
                                        <div className="col"> We will send a one-time password to the email to reset your password</div>
                                    </div>
                                    <div className="row align-items-center mb-4">
                                        <div className="col">
                                            <InputField
                                                id="email"
                                                className="input"
                                                type="email"
                                                name="email"
                                                value={fields['email']}
                                                placeholder="Email"
                                                error={errors['email']}
                                                onChange={(value, name) => this.handleChange({ target: { value, name } })}
                                                disabled={verifyOtp}
                                            />
                                        </div>
                                    </div>
                                    {verifyOtp &&
                                        <div className="row mb-4">
                                            <div className="col-12">
                                                <InputField
                                                    id="otp"
                                                    name="otp"
                                                    type="number"
                                                    autoComplete="off"
                                                    className="input"
                                                    placeholder='Enter OTP'
                                                    tabIndex="1"
                                                    value={fields['otp']}
                                                    error={errors['otp']}
                                                    maxLength="6"
                                                    onChange={(value, name) => this.handleChange({ target: { value, name } })}
                                                />
                                            </div>
                                        </div>}
                                    <div className="text-center pt-4 mb-4">
                                        <AppButton title={verifyOtp ? "VERIFY OTP" : "SEND OTP"} onClick={() => verifyOtp ? this.verifyotp() : this.requestOtp()} />
                                    </div>
                                    <div className="text-start">
                                        <span id="LoginClick" className="text-dark cursor-pointer" onClick={() => this.openLoginPage()}><strong>Back to login</strong></span>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {isVerifyOtpLoading && <AppLoader />}
            </>
        );

    }
}


export const mapStateToProps = (state) => {
    return {
        getForgetEmail: Actions.getForgetEmail(state)
    };
};

export default withRouter(connect(mapStateToProps, {
    requestOtp: Actions.requestOtpAction,
    updateCurrentScreen: Actions.updateCurrentScreen,
    forgetPasswordVerifyOtp: Actions.forgetPasswordVerifyOtp,
    successEmail: Actions.storeForgetEmail
})(ForgotPassword))
