
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const PROMOTION_BASE_URL = process.env.REACT_APP_PROMOTION_BASE_URL
export const BASE_URL_SOCKET_IO = process.env.REACT_APP_SOCKET_URL;
export const SOCKET_IO_PATH = process.env.REACT_APP_SOKENT_PATH;
export const MAP_API_KEY = 'AIzaSyD6zJBTkhrM6-heVR33nw8HLuxhuanm78A';

const END_POINTS = {
    CLIENT_CREDENTIALS: '/oauth/token',
    LOGIN_API: '/api/signin',
    FORGET_PASSWORD: '/forgotPassword/emailId/otp',
    FORGOT_PASSWORD_VERIFY_OTP: '/forgotPassword/verify',
    UPDATE_FORGOT_PASSWORD: '/user/forgot/password',
};

export default END_POINTS;
