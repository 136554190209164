import { takeLatest, all, put } from 'redux-saga/effects';
import END_POINTS from "../../utils/service/Endpoints";
import { doClientPost, clientCredentials } from "../../utils/service";
import Actions from '../actions';

export function* requestOtpSaga({ data, callback }) {
  try {
    const formData = new FormData();
    formData.append('grant_type', 'client_credentials')
    const clientRes = yield clientCredentials(END_POINTS.CLIENT_CREDENTIALS, formData);
    yield put(Actions.clientCredentialsSuccess(clientRes));
  } catch (error) {
    yield put(Actions.clientCredentialsFailure(error));
  }

  try {
    let response = yield doClientPost(END_POINTS.FORGET_PASSWORD, data)
    yield Actions.requestOtpSuccess(response)
    if (callback) {
      callback(response)
    }
  } catch (error) {
    if (callback) {
      callback({ error })
    }
    yield Actions.requestOtpFailure(error)
  }


}

export function* forgetPasswordVerifyOtp({ data, callback }) {
  try {
    const formData = new FormData();
    formData.append('grant_type', 'client_credentials')
    const clientRes = yield clientCredentials(END_POINTS.CLIENT_CREDENTIALS, formData);
    yield put(Actions.clientCredentialsSuccess(clientRes));

  } catch (error) {
    yield put(Actions.clientCredentialsFailure(error));
  }

  try {
    let response = yield doClientPost(END_POINTS.FORGOT_PASSWORD_VERIFY_OTP, data)
    if (response?.response_code === 0) {
      yield put(Actions.clientCredentialsSuccess(response?.response));
      yield put(Actions.forgetPasswordVerifyOtpSuccess())
    } else {
      yield put(Actions.forgetPasswordVerifyOtpFailure());
    }
    if (callback) {
      callback(response);
    }

  } catch (error) {
    if (callback) {
      callback({ error })
    }
    yield put(Actions.forgetPasswordVerifyOtpFailure());

  }
}

export function* resetPassword({ data, callback }) {
  try {
    let response = yield doClientPost(END_POINTS.UPDATE_FORGOT_PASSWORD, data)
    if (response?.response_code === 0) {
      callback && callback(response && response.response);
    }else {
      callback && callback(response);
    }
    yield put(Actions.loginSuccessAction(response && response.response))

  } catch (error) {
    yield put(Actions.updatePasswordFailure());
    if (callback) {
      callback({ error })
    }
  }
}


export default function* forgotPasswordWatcher() {
  yield all([
    takeLatest(Actions.REQUEST_OTP, requestOtpSaga),
    takeLatest(Actions.FORGET_PASSWORD_VERIFY_OTP, forgetPasswordVerifyOtp),
    takeLatest(Actions.UPDATE_PASSWORD, resetPassword)
  ]);
}
