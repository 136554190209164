import { put, takeLatest } from "redux-saga/effects";
import MomentFunctions from "../../utils/commonFunctions/MomentFunctions";
import { doClientPost, doAuthPost, refreshToken, clientCredentials } from "../../utils/service";
import END_POINTS from "../../utils/service/Endpoints";
import Actions from "../actions";
import { LOGIN, LOGOUT, REFRESH_TOKEN_ACTION } from "../actions/LoginAction";

export function* login({ params, callback }) {
    try {
        const formData = new FormData();
        formData.append('grant_type', 'client_credentials')
        const clientRes = yield clientCredentials(END_POINTS.CLIENT_CREDENTIALS, formData);
        yield put(Actions.clientCredentialsSuccess(clientRes));
    } catch (e) {
        yield put(Actions.clientCredentialsFailure(e));
    }
    try {
        params.type = 2;
        const response = yield doClientPost(END_POINTS.LOGIN_API, params);
        if (response?.access_token) {
            response.expMilliseconds = MomentFunctions.convertExpirySecToMilliSec(response.expires_in);
            yield put(Actions.loginSuccessAction(response));
            callback && callback(response);

        } else {
            yield put(Actions.loginFailureAction());
            callback && callback(response);
            // throw response?.data;
        }
    } catch (e) {
        yield put(Actions.loginFailureAction(e));
        callback && callback(e)
    }
}


export function* logoutUser({ callback }) {
    try {
        let response = yield doAuthPost(END_POINTS.SIGN_OUT_API)
        if (response?.response_code === 0) {
            yield put(Actions.logoutSuccessAction(response));
        }
        if (callback) {
            callback(response);
        }
    } catch (error) {
        yield put(Actions.logoutFailureAction({ error }));
        yield put(Actions.logoutSuccessAction({}));
    }
}


export function* getRefreshToken({ data, callback }) {
    try {
        const response = yield refreshToken(data);
        if (response && response.access_token) {
            response.expMilliseconds = MomentFunctions.convertExpirySecToMilliSec(response.expires_in);
        }
        if (callback) {
            callback(response)
        }
        yield put(Actions.loginSuccessAction(response))
    } catch (error) {
        if (callback) {
            callback({ error })
        }
        yield put(Actions.logoutAction());
    }
}
export default function* loginSagaListener() {
    yield takeLatest(LOGIN, login);
    yield takeLatest(LOGOUT, logoutUser);
    yield takeLatest(REFRESH_TOKEN_ACTION, getRefreshToken)
}